import React from "react"
import styled from "@emotion/styled"

import { createHTML } from "../utils"

const Container = styled.section`
  max-width: 130rem;
  margin: 3rem auto 9rem;
  padding: 0 1.5rem;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  padding-bottom: 1.5rem;
  color: #8cc949;
  background: linear-gradient(to right, #8cc949, #78B526);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const ParagraphsContainer = styled.div`
    text-align: left;
    text-indent: 45px;
    font-weight: 400;
    font-size: 1.9rem;

    @media only screen and (min-width: 52em) {
        padding: 0 10rem;
    }
`

const Blurb = ({ data: { title, blurb }, id }) => {
    return (
        <Container id={id}>
            {title && (
                <Primary>{title}</Primary>
            )}
            <ParagraphsContainer dangerouslySetInnerHTML={createHTML(blurb)} />
        </Container>
    )
}

export default Blurb
