import React from "react"
import styled from "@emotion/styled"


const Container = styled.section`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 50rem;
  height: 28rem;
  iframe {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    border: 0;
  }
`

const Video = ({ src }) => (
  <Container>
    <iframe
      src={src}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Video Title"
    ></iframe>
  </Container>
)

export default Video
